<template>
  <b-container v-if="isSelfMember">
    <b-alert :show="!!error" variant="danger">
      {{ error ? (error.response.data || error.response.statusText || error.response.status) : '' }}
    </b-alert>

    <b-row align-h="center" class="buttonGroup mt-0 mb-3">
      <b-button v-if="canAddMember" variant="secondary" to="users/new">追加する</b-button>
      <b-button v-else variant="outline-danger" disabled>追加上限に達しています</b-button>
    </b-row>

    <b-row align-h="center" class="mb-4">
      <b-input-group class="mb-2" style="flex-wrap:nowrap">
        <template #prepend>
          <b-input-group-text class="bg-white font-smaller" style="white-space:normal">メンバーに仮登録をしてもらう<span class="d-none d-sm-inline">にはこちら</span></b-input-group-text>
        </template>
        <b-input v-model="registorMemberUrl" id="registorMemberUrl"
            class="font-smaller d-none d-sm-inline" style="height:auto" :readonly="true"></b-input>
        <b-input-group-append>
          <b-button variant="outline-info" class="font-smaller" @click="copyUrlToClipboard()"><b-icon-clipboard class="mr-1"/>コピー<span class="d-none d-sm-inline">する</span></b-button>
          <b-dropdown variant="outline-info" right>
            <b-dropdown-item class="font-smaller" @click="republishCorporationTokenWithConfirm()"><b-icon-arrow-clockwise class="mr-1"/>再発行<span class="d-none d-sm-inline">する</span></b-dropdown-item>
          </b-dropdown>
        </b-input-group-append>
      </b-input-group>

      <b-table v-show="tempUsers.length && !loading" :busy.sync="loading" thead-class="bg-secondary text-white" class="text-center"
          :items="tempUsers" :fields="tempUsersFields" 
          striped hover>
        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="field.style || {}">
        </template>
        <template v-slot:table-busy>
          <div class="table-loading text-center my-2">
            <strong><b-spinner class="align-middle mr-2"></b-spinner>読み込み中...</strong>
          </div>
        </template>
        <template v-slot:cell(create_datetime)="{ item }">
          {{ item.create_date }} {{ item.create_time.substring(0, 5) }}
        </template>
        <template v-slot:cell(edit)="{ item }">
          <router-link :to="{ name: 'UserNewTemp', params: { id: item.temp_user_id }}">
            <b-button variant="outline-secondary" :disabled="!canAddMember">本登録<span class="d-none d-sm-inline">する</span></b-button>
          </router-link>
          <b-button variant="outline-danger" class="ml-1" @click="deleteTempUserWithConfirm(item.temp_user_id)">破棄<span class="d-none d-sm-inline">する</span></b-button>
        </template>
      </b-table>    
    </b-row>

    <b-row align-h="center" class="m-5" v-if="isEmpty">
      <p class="font-weight-bold text-dark" style="font-size: larger;">まだメンバーの登録がありません。</p>
      <p class="font-weight-light text-secondary">メンバーに仮登録のアドレスから入力してもらうか、追加するボタンより入力をしてください。</p>
    </b-row>

    <b-row align-h="center" v-if="users.length && !loading">

      <div class="mb-2">
        <b-input v-model="filter" type="search" id="filterInput" placeholder="検索"></b-input>
      </div>

      <b-table :busy.sync="loading" thead-class="c-theme" class="text-center"
          :items="users" :fields="fields" 
          :filter="filter" :filterIncludedFields="filterOn"
          striped hover>
        <template v-slot:table-busy>
          <div class="table-loading text-center my-2">
            <strong><b-spinner class="align-middle mr-2"></b-spinner>読み込み中...</strong>
          </div>
        </template>
        <template v-slot:table-colgroup="scope">
          <col v-for="field in scope.fields"
            :key="field.key"
            :style="field.style || {}">
        </template>

        <template v-slot:head(user_id)="data">
          <div class="d-none d-sm-inline">{{ data.label }}</div>
        </template>
        <template v-slot:head(stats)="data">
          <div class="d-none d-sm-inline">{{ data.label }}</div>
        </template>
        <template v-slot:head(mobile)="data">
          <div class="d-none d-sm-inline"><span v-if="useMobile">{{ data.label }}</span></div>
        </template>
        
        <template v-slot:cell(tag_names)="{ item }">
          <b-tag v-for="tagName in item.tag_names" class="userTag" :key="tagName" :no-remove="true">{{ tagName }}</b-tag>
        </template>
        <template v-slot:cell(edit)="{ item }">
          <router-link :to="{ name: 'UserUpdate', params: { id: item.user_id }}">
            <b-button class="mx-1" variant="outline-secondary">編集<span class="d-none d-sm-inline">する</span></b-button>
          </router-link>
        </template>
        <template v-slot:cell(stats)="{ item }">
          <template v-if="item.meta.registed">
            <div v-if="item.last_message_id">
              <span class="text-nowrap" v-if="item.meta.delivery_count" v-b-popover.hover.top="'配信成功'">
                <b-icon-envelope-open scale="1.2" variant="success"/>
                <code class="mailCount ml-1" v-if="item.meta.delivery_count > 1">{{ item.meta.delivery_count || '-' }}</code>
              </span>
              <span class="text-nowrap" v-if="item.meta.bounce_count" v-b-popover.hover.top="'配信失敗'" :class="{'ml-sm-2': item.meta.delivery_count}">
                <b-icon-exclamation-triangle-fill scale="1.2" variant="warning"/>
                <code class="mailCount ml-1" v-if="item.meta.bounce_count > 1">{{ item.meta.bounce_count || '-' }}</code>
              </span>
              <span v-if="!item.meta.delivery_count && !item.meta.bounce_count" v-b-popover.hover.top="'配信中'">
                <b-icon-envelope scale="1.2" variant="secondary"/>
              </span>
            </div>
            <div v-else>
              <b-icon-dash scale="1.2" v-b-popover.hover.top="'未配信'"/>
            </div>
          </template>
          <span class="text-nowrap" v-else v-b-popover.hover.top="'配信先なし'">
            <b-icon-exclamation-triangle scale="1.2" variant="danger"/>
            <span class="smaller text-secondary">配信先なし</span>
          </span>
        </template>
        <template v-slot:cell(mobile)="{ item }">
          <b-button v-if="useMobile" class="mx-1" variant="outline-success" 
              :style="{ 'min-width': '105px', 'border-style': (!item.device_token || item.user_id == hoverItem ? 'solid' : 'none') }"
              @mouseover="hoverItem = item.user_id" @mouseleave="hoverItem = null"
              @click="downloadGuidePdf(item.user_id)" v-b-popover.hover.right="item.device_token ? '連携済み' : ''">
            <template v-if="!item.device_token || item.user_id == hoverItem">
              <span class="smaller mr-1">連携情報</span><b-icon icon="cloud-download"/>
            </template>
            <b-icon icon="check" v-else></b-icon>
          </b-button>
        </template>
      </b-table>    
    </b-row>

    <b-row align-h="center" class="buttonGroup">
      <b-button v-if="canAddMember" variant="secondary" to="users/new">追加する</b-button>
      <b-button v-else variant="outline-danger" disabled>追加上限に達しています</b-button>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Users',
  data () {
    return {
      loading: false,
      tempUsersFields: [
        { key: 'create_datetime', label: '仮登録日時' },
        { key: 'user_name', label: '氏名', tdClass: 'text-nowrap' },
        { key: 'edit', label: '' } 
      ],
      tempUsers () {
        return []
      },
      fields: [
        { key: 'user_id', label: 'メンバーID' },
        { key: 'user_name', label: '氏名', tdClass: 'text-nowrap' },
        { key: 'tag_names', label: '所属／分類' },
        { key: 'edit', label: '', style: { 'min-width': '110px' } },
        { key: 'stats', label: '直近の配信', style: { width: '110px' } },
        { key: 'mobile', label: 'アプリ連携' }
      ],
      filter: null,
      filterOn: ['user_name', 'tag_names'],
      users () {
        return []
      },
      hoverItem: null,
      corporationToken: null,
      error: null
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    ...mapGetters({
      useMobile: 'auth/useMobile',
      sortedUserTagNames: 'auth/sortedUserTagNames'
    }),
    canAddMember() {
      if (this.$store.state.auth.corporation) {
        return this.$store.state.auth.corporation.max_members > this.users.length
      } else {
        return false
      }
    },
    registorMemberUrl() {
      if (this.corporationToken) {
        const rootUrl = window.location.href.match(/http(s)?:\/\/[a-zA-Z0-9-.!'()*;?:@&=+$,%#]+/)[0]
        return rootUrl + '/#/viewuser/' + this.corporationId + '/' + this.corporationToken
      }
      return ''
    },
    isEmpty() {
      return !this.loading && this.users.length == 0 && this.tempUsers.length == 0
    },
    isSelfMember() {
      return this.corporationId == this.memberCorporationId
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.loading = true
      this.error = null

      this.publishCorporationToken(false)
      this.fetchTempUserData()
      this.fetchUserData()
    },

    fetchUserData () {
      const params = {
        'corporation_id': this.memberCorporationId
      }
      this.$store.dispatch('http/get', { apiName: 'canaryEmployee', params: params }, { root: true })
        .then(res => {
          if (res.data) {
            this.users = res.data
            this.users.forEach(user => {
              user.tag_names = this.sortedUserTagNames(user.tags)
            })
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.error = res.response
        })
        .finally(() =>
          this.loading = false
        )
    },

    fetchTempUserData () {
      const params = {
        'corporation_id': this.corporationId
      }
      this.$store.dispatch('http/get', { apiName: 'canaryDynamo', path: '/temporary_employee', params: params }, { root: true })
        .then(res => {
          if (res.data) {
            res.data.sort((a, b) => {
              if (a.create_date > b.create_date) return 1
              if (a.create_date < b.create_date) return -1
              if (a.create_time > b.create_time) return 1
              if (a.create_time < b.create_time) return -1
              return 0
            })
            this.tempUsers = res.data
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.error = res.response
        })
    },

    copyUrlToClipboard () {
      const urlEl = document.getElementById('registorMemberUrl')

      let succeeded = true
      if (window.clipboardData) {
        // For IE 
        window.clipboardData.setData('Text', urlEl.value)
      } else if (navigator.clipboard) {
        // For modan browser
        navigator.clipboard.writeText(urlEl.value)
      } else {
        // case-1. select()でtextarea内の文字を選択
        urlEl.select()
        // case-2. rangeでtextarea内の文字を選択
        const range = document.createRange()
        const sel = window.getSelection()
        range.selectNodeContents(urlEl)
        sel.removeAllRanges()
        sel.addRange(range)
        urlEl.setSelectionRange(0, 999999)
        // execCommandにてクリップボードにコピー
        succeeded = document.execCommand('copy')
      }

      if (succeeded) {
        this.msgBoxOk('コピーに成功しました')
      } else {
        this.msgBoxOk('コピーに失敗しました')
      }
    },

    publishCorporationToken(isReload) {
      const params = {
        'corporation_id': this.corporationId,
        'reload': isReload
      }
      this.$store.dispatch('http/get', { apiName: 'canaryCorpToken', params: params }, { root: true })
        .then(res => {
          if (res.data) {
            this.corporationToken = res.data.id_token
          } else {
            this.corporationToken = null
          }
        })
        .catch(() => {
          this.corporationToken = null
        })
    },

    republishCorporationTokenWithConfirm() {
      this.msgBoxConfirm('再発行すると表示中の仮登録アドレスは無効となります。再発行してよろしいですか？')
        .then(val => {
          if (val) this.publishCorporationToken(true)
        })
    },

    deleteTempUserWithConfirm(tempUserId) {
      this.msgBoxConfirm('仮登録依頼を破棄します。よろしいですか？', {okTitle: '破棄する'})
        .then(val => {
          if (val) {
            const params = {
              'corporation_id': this.corporationId,
              'temp_user_id': tempUserId
            }
            this.$store.dispatch('http/delete', { apiName: 'canaryDynamo', path: '/temporary_employee', data: params }, { root: true })
              .then(() => {
                this.msgBoxOk('仮登録依頼を破棄しました')
                this.fetchTempUserData() // 再取得
              })
              .catch(() => {
                this.msgBoxOk('仮登録依頼の破棄に失敗しました')
              })
          }
        })
    },

    downloadGuidePdf(userId) {
      const action = async () => {
        const params = {
          'corporation_id': this.corporationId,
          'user_id': userId
        }
        await this.$store.dispatch('http/download', { apiName: 'canaryGuide4AppPdf', params: params }, { root: true })
          .catch(() => {
            this.msgBoxOk('アプリ連携情報のダウンロードに失敗しました')
          })
      }
      this.$emit('overlay', {action, 'message': '連携情報ファイルを作成しています...'})
    }
  }

}
</script>

<style scoped>
.popover {
  border-color: gray;
}
.mailCount {
  color: #343a40 !important;
  vertical-align: bottom;
}
</style>