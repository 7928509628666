<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" class="error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>
    
    <b-alert :show="!!warning" variant="warning" class="mb-4" dismissible>
      <b-icon-exclamation-triangle class="lead mr-2"/>{{ warning }}
    </b-alert>

    <div v-if="!loading">
      <b-row class="headerAttr my-2">
        <b-col md="2" offset-md="3">
          <span>件名：</span>
        </b-col>
        <b-col md="7">
          <span class="ml-3">{{ summary.subject }}</span>
        </b-col>
      </b-row>
      <b-row class="headerAttr my-2">
        <b-col md="2" offset-md="3">
          <span>送信日時：</span>
        </b-col>
        <b-col md="7">
          <span class="ml-3">{{ summary.create_date }} {{ summary.create_time }}</span>
        </b-col>
      </b-row>
      <b-row class="headerAttr my-2">
        <b-col md="2" offset-md="3">
          <span>配信先：</span>
        </b-col>
        <b-col md="7">
          <span class="ml-3">{{ summary.summary_count.total }}名</span>
        </b-col>
      </b-row>
      <b-row class="headerAttr my-2">
        <b-col md="2" offset-md="3">
          <span>集計結果：</span>
        </b-col>
        <b-col md="7">
          <span class="ml-3">{{ toDisclosureName(summary.disclosure) }}</span>
        </b-col>
      </b-row>

      <ResponseSummaryItems :summary.sync="summary" :detail="true" @detail-click="moveToDetail"/>

      <b-row class="buttonGroup text-center" v-if="totalUnresponse > 0">
        <b-col>
          <router-link :to="{ name: 'ResendMail', params: { id: questionnaireId }}">
            <b-button id="resendButton" variant="outline-primary">未回答者に再送信する</b-button>
          </router-link>
        </b-col>
      </b-row>

      <b-row class="buttonGroup text-center">
        <b-col>
          <b-button id="deleteButton" variant="outline-danger" @click="deleteDataWithConfirm">
            <b-spinner small v-if="sending"></b-spinner>削除する
          </b-button>
        </b-col>
      </b-row>

      <v-tour name="helpTour" class="helpTour" :steps="dynamicSteps()" :options="tourOptions"></v-tour>
      <b-button variant="warning" class="help" @click="$tours['helpTour'].start()">
        <b-icon-chat-square-text class="helpIcon"/><span class="helpCaption ml-2">ヘルプ</span>
      </b-button>

    </div>
  </b-container>
</template>

<script>
import DISCLOSURE_OPTIONS from '@/const/disclosureOptions'
import ResponseSummaryItems from '@/components/ResponseSummaryItems.vue'

export default {
  name: 'ResponseSummary',
  props: ['questionnaireId'],
  components: {
    ResponseSummaryItems
  },
  data () {
    return {
      loading: false,
      sending: false,
      error: null,
      warning: null,
      tourOptions: {
        useKeyboardNavigation: true,
        labels: { buttonSkip: '中断', buttonPrevious: '← 前', buttonNext: '次 →', buttonStop: '終了' }
      }
    }
  },
  computed: {
    totalUnresponse() {
      return this.summary.summary_count.total - this.summary.summary_count.responsed
    }
  },
  created () {
    this.fetchData()
  },
  destroyed () {
    this.$tours['helpTour'].stop()
  },
  watch: {
    '$route': 'fetchData'
  },
  filters: {
    toParcentage (value) {
      return (parseFloat(value) * 100).toFixed(1) + '%' 
    }
  },
  methods: {
    toDisclosureName(val) {
      if (val === undefined) val = 0
      const el = DISCLOSURE_OPTIONS.find(el => el.value == val)
      return el ? el.text : ''
    },

    dynamicSteps () {
      const DEFAULT_STEP_PARAMS = { placement: 'top', highlight: false, enableScrolling: false }
      let steps = []
      steps.push({
        target: '#responseStats',
        content: '回答状況が表示されます。',
        params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
      })
      if ((this.summary.summary_item || []).length) {
        steps = steps.concat([
          {
            target: '[aria-controls="responseItems"]',
            content: '<b>質問・選択肢毎の回答</b>が表示されます。',
            before: async () => {this.tabIndex = 0},
            params: DEFAULT_STEP_PARAMS
          },
          {
            target: '.responseMembers',
            content: '回答者ボタンで回答毎の氏名リンクが表示、<br>氏名リンク押下でメンバー毎の回答が表示されます。',
            before: async () => {this.tabIndex = 0},
            params: DEFAULT_STEP_PARAMS
          }
        ])
      } else {
        steps.push({
          target: '[aria-controls="responseItems"]',
          content: '<b>質問・選択肢毎の回答</b>が表示されます。<br>回答者ボタンで回答毎の氏名リンクが表示、<br>氏名リンク押下でメンバー毎の回答が表示されます。',
          before: async () => {this.tabIndex = 0},
          params: DEFAULT_STEP_PARAMS
        })
      }
      steps.push({
        target: '[aria-controls="responseMembers"]',
        content: '<b>回答者、未回答者のリスト</b>が表示されます。<br>氏名リンク押下でメンバー毎の回答が表示されます。',
        before: async () => {this.tabIndex = 1},
        params: DEFAULT_STEP_PARAMS
      })
      if (this.totalUnresponse)
        steps.push({
          target: '#resendButton',
          content: '未回答者にメールを再送することができます。',
          params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
        })
      steps.push({
        target: '#deleteButton',
        content: '不要になった過去の回答情報を削除できます。<br>削除は元に戻せませんので注意してください。',
        params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
      })
      return steps
    },

    fetchData () {
      this.loading = true

      const params = {
        'corporation_id': this.corporationId,
        'questionnaire_id': this.questionnaireId
      }
      this.$store.dispatch('http/get', { apiName: 'canaryQResult', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.summary = res.data[0]
            if (this.summary.summary_user.not_responsed.some(user => user.unsend || user.undelivery)) {
              this.warning = '配信に失敗しているメンバーがいます。未回答者をご確認ください。'
            }
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.summary = null
          this.error = res.response
        })
    },

    moveToDetail (ruid) {
      this.$router.push({ name: 'ResponseDetail', params: { questionnaireId: this.questionnaireId, ruid: ruid }})
    },

    backToList () {
      this.$router.push({ name: 'Responses' })
    },

    deleteDataWithConfirm () {
      this.msgBoxConfirm('本当に削除してよろしいですか？', {okTitle: '削除する'})
        .then(val => {
          if (val) this.deleteData()
        })
    },

    deleteData () {
      const params = {
        'corporation_id': this.corporationId,
        'questionnaire_id': this.questionnaireId
      }

      this.sending = true
      this.$store.dispatch('http/delete', { apiName: 'canaryQResult', data: params }, { root: true })
        .then(() => {
          this.msgBoxOk('削除しました')
            .then(this.backToList)
        })
        .catch(res => {
          this.error = res.response
        })
    }
  }

}
</script>