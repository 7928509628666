<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" class="error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>

    <b-alert :show="!!warning" variant="warning" class="mb-4" dismissible>
      <b-icon-exclamation-triangle class="lead mr-2"/>{{ warning }}
    </b-alert>

    <div v-if="!loading" class="inputForm">

      <div v-show="!isSelectUser">

        <b-form-group class="my-3 text-nowrap" label-cols-sm="2" label="回答公開" label-for="disclosure"
            description="回答者に集計結果を公開するかどうかを選択します">
          <b-form-radio-group id="disclosure" v-model="disclosure" button-variant="outline-success"
            :options="disclosureOptions" :size="radioSize" buttons>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group class="my-2" label-cols-sm="2" label="定型文選択" label-for="seltmpl">
          <b-input-group>
            <b-select id="seltmpl" v-model="selectedCaption" class="form-control" :autofocus="true" @change="changeCaption">
              <option disabled value="">定型文を適用する場合は選択</option>
              <option v-for="template in templates" v-bind:value="template.caption" v-bind:key="template.caption">
                {{ template.caption }}
              </option>
            </b-select>
            <b-input-group-append>
              <b-button id="applyTemplate" @click="applyTemplate"
                :variant="isChangeCaption ? 'secondary' : 'outline-secondary'"
                :class="{'focus': isChangeCaption, 'form-control': !isChangeCaption}">適用</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <div id="messageContent">

          <b-row class="mt-4 mb-3 text-center">
            <b-col>＜配信内容＞</b-col>
          </b-row>

          <b-form-group class="my-2" label-cols-sm="2" label="件名" label-for="subject">
            <b-input id="subject" v-model="template.subject" autocomplete="off" :required="true"/>
          </b-form-group>
          <b-row v-if="$v.template.subject.$error">
            <b-col offset-sm="2">
              <div class="error" v-if="!$v.template.subject.required">件名を入力してください</div>
            </b-col>
          </b-row>

          <b-form-group class="my-2" label-cols-sm="2" label="本文" label-for="body">
            <b-form-textarea id="body" v-model="template.body" rows="4" max-rows="8"></b-form-textarea>
            <span class="smaller text-info font-italic">※本文以下に回答のためのリンクが挿入されます</span>
          </b-form-group>
          <b-row v-if="$v.template.body.$error">
            <b-col offset-sm="2">
              <div class="error" v-if="!$v.template.body.required">本文を入力してください</div>
            </b-col>
          </b-row>

          <QuestionnaireItems :template="template" :validator="$v.template"/>

        </div>
        
      </div>

      <div v-show="isSelectUser"><!-- load時に初期化させるためv-showにて対応 -->
        <b-row class="mt-4 mb-3 text-center">
          <b-col>＜送信先選択＞</b-col>
        </b-row>

        <SelectUsers ref="selectUsers" @selected="onSelectUsers"></SelectUsers>
      </div>
      
      <b-row id="sticky-bottom" class="buttonGroup mt-4 text-center">
        <b-col>
          <div v-if="!isSelectUser">
            <b-button id="sendButton" variant="primary" @click="createDataWithConfirm">
              <b-spinner small v-if="sending"></b-spinner><b-icon-envelope v-if="!sending" class="mr-2"/>送信する
            </b-button>
            <span class="mx-1 mx-sm-2"/>
            <b-button id="selectButton" variant="outline-primary" @click="viewSelectUser">
              <b-icon-people class="mr-2"/>送信先を選択する
            </b-button>
          </div>
          <div v-else>
            <b-button id="backButton" variant="secondary" @click="cancelSelectUser">戻る</b-button>
            <span class="mx-2"/>
            <b-button id="sendButton" variant="primary" @click="createDataWithConfirm" :disabled="!selectedUserId.length">
              <b-spinner small v-if="sending"></b-spinner><b-icon-envelope v-if="!sending" class="mr-2"/>送信する（{{ selectedUserId.length }}名）
            </b-button>
          </div>
        </b-col>
      </b-row>

      <v-tour name="helpTour" class="helpTour" :steps="dynamicSteps()" :options="tourOptions"></v-tour>
      <b-button variant="warning" class="help" @click="$tours['helpTour'].start()">
        <b-icon-chat-square-text class="helpIcon"/><span class="helpCaption ml-2">ヘルプ</span>
      </b-button>

    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import { default as qHandler } from '@/const/questionnaireHandler'

import DISCLOSURE_OPTIONS from '@/const/disclosureOptions'
import QuestionnaireItems from '@/components/QuestionnaireItems.vue'
import SelectUsers from '@/components/SelectUsers.vue'

export default {
  name: 'SendMail',
  components: {
    QuestionnaireItems,
    SelectUsers
  },
  data () {
    return {
      loading: false,
      sending: false,
      isSelectUser: false,
      selectedUserId: [],
      selectedCaption: '',
      isChangeCaption: false,
      disclosure: 0,
      templates: [],
      template: {
        confirm_item: []
      },
      error: null,
      warning: null,
      disclosureOptions: DISCLOSURE_OPTIONS,
      radioSize: {},
      tourOptions: {
        useKeyboardNavigation: true,
        labels: { buttonSkip: '中断', buttonPrevious: '← 前', buttonNext: '次 →', buttonStop: '終了' }
      },
      steps: []
    }
  },
  computed: {
    ...mapGetters({
      generateShortId: 'app/generateShortId',
    })
  },
  validations: {
    template: {
      subject: {
        required
      },
      body: {
        required
      },
      confirm_item: {
        $each: {
          question: {
            required,
          },
          choice: {
            $each: {
              required
            }
          },
          choiceMinLength: qHandler.choiceMinLength
        }
      }
    }
  },
  created () {
    this.fetchData()
    const resizeHandler = () => {this.radioSize = window.innerWidth <= 800 ? 'sm' : 'md'}
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
  },
  destroyed () {
    this.$tours['helpTour'].stop()
  },
  methods: {
    dynamicSteps () {
      const DEFAULT_STEP_PARAMS = { placement: 'top', highlight: false, enableScrolling: false }
      let steps
      if (!this.isSelectUser) {
        steps = [
          {
            target: '#disclosure',
            content: '回答者に集計結果を公開するかを選択します。<br>「全て」は誰がどの回答をしたかも公開されます。',
            params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
          },
          {
            target: '#seltmpl',
            content: '<b>作成済みの定型文をもとにして送信する場合</b><br>定型文を選んで適用ボタンをクリックしてください。<br>適用せずにメール送信することも可能です。',
            params: DEFAULT_STEP_PARAMS
          },
          {
            target: '#subject',
            content: '送信するメールの件名を設定します。',
            params: DEFAULT_STEP_PARAMS
          },
          {
            target: '#body',
            content: '送信するメールの本文を設定します。<br>実際には本文以下に<b>回答のためのリンク</b>が<br>挿入されます。',
            params: DEFAULT_STEP_PARAMS
          }]
        if (this.template.confirm_item.length) steps = steps.concat([
          {
            target: '.question',
            content: '質問を入力してください。',
            params: DEFAULT_STEP_PARAMS
          },
          {
            target: '.questionType',
            content: '必要に応じて質問形式を選択してください。',
            params: DEFAULT_STEP_PARAMS
          },
          {
            target: '.choice',
            content: '回答の選択肢を入力してください。',
            params: DEFAULT_STEP_PARAMS
          },
          {
            target: '.addChoice',
            content: '必要に応じて回答の選択肢を追加できます。',
            params: DEFAULT_STEP_PARAMS
          },
          {
            target: '.delChoice',
            content: '不要な選択肢を削除できます。',
            params: DEFAULT_STEP_PARAMS
          },
          {
            target: '.choiceRequired',
            content: '質問に対する回答が必須かどうか設定できます。',
            params: DEFAULT_STEP_PARAMS
          },
          {
            target: '.delItem',
            content: '不要な質問を削除できます。',
            params: DEFAULT_STEP_PARAMS
          },
        ])
        steps = steps.concat([
          {
            target: '.addItem',
            content: '必要に応じて質問を追加できます。',
            params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
          },
          {
            target: '#sendButton',
            content: '最後に送信ボタンをクリックして完了です。',
            params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
          },
          {
            target: '#selectButton',
            content: '送信先を選択することもできます。',
            params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
          }
        ])
        return steps
      } else {
        steps = this.$refs.selectUsers.dynamicSteps()
        steps = steps.concat([
          {
            target: '#sendButton',
            content: '最後に送信ボタンをクリックして完了です。',
            params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
          }
        ])
        return steps
      }
    },

    toDisclosureName(val) {
      if (val === undefined) return ''
      const el = DISCLOSURE_OPTIONS.find(el => el.value == val)
      return el ? el.text : ''
    },

    fetchData () {
      this.loading = true

      const params = {
        'corporation_id': this.corporationId
      }
      // 定型文取得
      this.$store.dispatch('http/get', { apiName: 'canaryDynamo', path: '/questionnaire_template', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.templates = res.data
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.error = res.response
        })
      // メンバー取得
      const params2 = {
        'corporation_id': this.memberCorporationId
      }      
      this.$store.dispatch('http/get', { apiName: 'canaryEmployee', params: params2 }, { root: true })
        .then(res => {
          if (res.data) {
            const users = res.data
            if (users.length) {
              if (users.filter(v => !v.meta.registed).length) {
                this.warning = '注意：配信先が登録されていないメンバーが存在します'
              }
            } else {
              this.error = 'メンバーが登録されていません'
            }
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
    },
    
    changeCaption () {
      this.isChangeCaption = true
    },
    
    applyTemplate () {
      const target = this.selectedCaption
      const tmpl = this.templates.find(function(value){
        return value.caption == target
      })
      if (tmpl) {
        this.isChangeCaption = false
        this.template = JSON.parse(JSON.stringify(tmpl))
        this.template.disclosure |= 0

        const contentEl = document.getElementById('messageContent')
        contentEl.classList.add('spotlight')
        contentEl.addEventListener('animationend', () => { contentEl.classList.remove('spotlight') })
      }
    },

    backToList () {
      this.$router.push({ name: 'Responses' })
    },

    createDataWithConfirm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const h = this.$createElement
      const userSize = this.selectedUserId.length
      const messageVNode = h('div', { class: ['text-left'] }, [
        h('p', 'この内容でメールを送信してよろしいですか？'),
        h('span', { class: ['ml-3'] }, ['送信先　： ', h('span', { class: ['text-primary'] }, userSize ? userSize + '名' : '全員')]), h('br'),
        h('span', { class: ['ml-3'] }, ['回答公開： ', h('span', { class: ['text-primary'] }, this.toDisclosureName(this.disclosure))])
      ])          
      this.msgBoxConfirm(messageVNode, {okVariant: 'primary', okTitle: '送信する', autoFocusButton: 'ok'})
        .then(val => {
          if (val) this.createData()
        })
    },

    createData () {
      const params = this.template
      params['corporation_id'] = this.corporationId
      params['questionnaire_id'] = this.$store.getters['app/generateUuid']()
      params['user_id'] = this.selectedUserId
      params['disclosure'] = this.disclosure
      params['with_status'] = true
      if (this.disclosure)
        params['secret_token'] = this.generateShortId()

      this.sending = true
      this.$store.dispatch('http/post', { apiName: 'canaryDynamo', path: '/questionnaire', data: params }, { root: true })
        .then(() => {
          const h = this.$createElement
          const messageVNode = h('div', { class: ['text-center'] }, [
            h('b-icon', { props: { icon: 'envelope', fontScale: 2.6 }, 
              style: { color: 'mediumseagreen', margin: '0 40px 5px 30px', animation: 'sending 1.5s ease-in-out infinite' } }),
            h('b-icon', { props: { icon: 'mailbox2', fontScale: 4.2 }, style: { color: 'mediumaquamarine' } }),
            h('p', { class: ['text-center'], style: { fontSize: '1.2rem', marginBottom: '0' }}, 'メールを送信しました')
          ])
          this.msgBoxOk(messageVNode)
            .then(this.backToList)
        })
        .catch(res => {
          this.error = res.response
        })
    },
    
    viewSelectUser () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.$refs.selectUsers.reset()
      this.isSelectUser = true
    },
    
    cancelSelectUser () {
      this.isSelectUser = false
      this.selectedUserId = []
      this.$refs.selectUsers.clearSelected()
    },

    onSelectUsers (users) {
      this.selectedUserId = users
    }
  }
}
</script>

<style scoped>
.choice { border-radius: 0.1rem !important; }
.delChoice { border: 0 !important; }
</style>